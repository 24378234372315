/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import "@fontsource/open-sans"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/open-sans/800.css"
import "@fontsource/raleway"
import "@fontsource/raleway/700.css"
import "@fontsource/raleway/800.css"
import '../style/common.css';


const Layout = ({ children }) => {
  return (
    <>
        <Header/>
        <main>{children}</main>
        <Footer />
    </>
  )
}

export default Layout
