/*
 * @Author: Nick
 * @Date: 2020-12-12 11:05:12
 * @LastEditors: Nick
 * @LastEditTime: 2021-01-24 00:39:53
 * @Description: 
 */
const type = process.env.REACT_APP_SECRET_BUILD_TYPE || 'development'
const IS_DEV = type === 'development'
const IS_PRO = type === 'production'
// eslint-disable-next-line
const IS_TEST = process.env.REACT_APP_CONFIG_IS_TEST == '1'

const Config = {
  baseUrl: process.env.REACT_APP_CONFIG_BASEURL,
  mainDomain: process.env.REACT_APP_CONFIG_MAINURL,
  appDomain: process.env.REACT_APP_CONFIG_APPDOMAIN,
  testToken: process.env.REACT_APP_CONFIG_TEST_TOKEN,

  User: {
    VerifyEmailCodeTime: 60 * 1000, // 用户验证邮箱 验证码 间隔 1 分钟
  },
}
// console.log({ IS_DEV, IS_PRO, IS_ELECTRON, IS_TEST, Config })
export { IS_DEV, IS_PRO, IS_TEST, Config }