import * as React from "react"
import { Config } from '../utils/config'
const Footer = () => (
    <footer className="bg-gray-light border border-t-[#efefef] border-t-2">
        <div className="container mx-auto">
            <div className="flex pt-10 md:flex-col">
                <div className="w-1/4 border-r border-[rgba(0,0,0,0.06)] md:w-full md:border-r-0 md:border-b md:pb-5">
                    <div className="font-bold text-lg pb-4">Products</div>
                    <ul>
                        <li><a className="block py-2" href={Config.appDomain}>Online</a></li>
                        <li><a className="block py-2" href="/pricing">Pricing</a></li>
                    </ul>
                </div>
                <div className="w-1/4 border-r border-[rgba(0,0,0,0.06)] pl-[2%] md:w-full md:border-r-0 md:pl-0 md:pt-6 md:border-b md:pb-5">
                    <div className="font-bold text-lg pb-4">Useful Links</div>
                    <ul>
                        {/* <li><a className="block py-2" href="/blog">Blogs</a></li> */}
                        <li><a className="block py-2" href="/faq">FAQ</a></li>
                        <li><a className="block py-2" href="/about">About Us</a></li>
                    </ul>
                </div>
                <div className="w-1/4 border-r border-[rgba(0,0,0,0.06)] pl-[2%] md:w-full md:border-r-0 md:pl-0 md:pt-6 md:border-b md:pb-5">
                    <div className="font-bold text-lg pb-4">Policies</div>
                    <ul>
                        <li><a className="block py-2" href="/privacy">Privacy policy</a></li>
                        <li><a className="block py-2" href="/terms">Terms & Conditions</a></li>
                    </ul>
                </div>
                <div className="w-1/4 text-center md:w-full md:text-left md:pt-8">
                    <svg viewBox="0 0 92.65 66" className="mx-auto w-[90px] md:mx-0"> <path fill="#1921b1" d="M0 6.79v54.24a4.98 4.98 0 009.96-.01V16.87l38.37 46.95a5.65 5.65 0 0010.01-3.59c0-1.33-.47-2.61-1.32-3.63L11.99 2.43A6.79 6.79 0 000 6.79z"></path> <path d="M59.65 5.39c0 2.4 1.72 4.45 4.08 4.87 10.8 1.93 19.02 11.4 19.02 22.74s-8.22 20.81-19.02 22.74a4.95 4.95 0 00-4.08 4.87 4.93 4.93 0 005.73 4.89C80.86 62.78 92.65 49.24 92.65 33S80.86 3.22 65.38.5a4.93 4.93 0 00-5.73 4.89z" fill="#ffbc00"></path> <path d="M74.83 33c0-5.65-3.11-10.59-7.7-13.21a5.05 5.05 0 00-7.48 4.45c0 1.77.88 3.47 2.43 4.32a5.06 5.06 0 01-.02 8.89 4.83 4.83 0 00-2.41 4.28v.13c0 3.8 4.07 6.25 7.37 4.41A15.19 15.19 0 0074.83 33z" fill="#ffbc00"></path> </svg>
                    <p className="text-gray font-bold pt-2.5 pb-5">NATURALVOICER</p>
                    <ul className="flex items-center justify-center md:justify-start">
                    <li className="pr-3"><a href="https://www.facebook.com/Naturalvoicer-102750328776544" className="w-8 h-8 rounded-full bg-[rgba(0,0,0,0.1)] hover:bg-blue flex justify-center items-center text-gray hover:text-white">
                        <svg viewBox="0 0 32 32" width="32">
                        <path fill="currentColor" d="M13.76 12.77H12v2.93h1.76V25h3.6v-9.3h2.43s.22-1.4.34-2.94h-2.75v-2c0-.3.4-.7.78-.7h1.97V7h-2.68c-3.78 0-3.7 2.94-3.7 3.37v2.4z"></path>
                        </svg>
                        </a></li>
                    <li className="pr-3"><a href="https://www.youtube.com/channel/UCmaZ2DrKb-XkTfZrNWE_NJA" className="w-8 h-8 rounded-full bg-[rgba(0,0,0,0.1)] hover:bg-blue flex justify-center items-center text-gray hover:text-white">
                    <svg viewBox="0 0 32 32" width="28"><path fill="currentColor" d="M27 8.6C26.3 7.5 24.7 7 21.6 7h-11c-3.2 0-4.8.5-5.7 1.6C4 9.8 4 11.5 4 13.8v4.4c0 4.5 1 6.8 6.5 6.8h11c2.7 0 4.2-.4 5.1-1.4 1-1 1.4-2.6 1.4-5.4v-4.4c0-2.5 0-4.1-1-5.2zm-8.4 8.1L14 19.4a.6.6 0 0 1-.7 0 .8.8 0 0 1-.3-.7v-5.4c0-.3.1-.5.3-.7a.6.6 0 0 1 .7 0l4.6 2.7c.2.1.4.4.4.7 0 .3-.2.6-.4.7z"/></svg>
                        </a></li>
                    </ul>
                </div>
            </div>
        </div>
        <p className="text-center text-small py-5 mt-10 border border-t-[rgba(0,0,0,0.06)] opacity-60">© 2021 Naturalvoicer Limited. All rights reserved.</p>
    </footer>
)

export default Footer