import Cookies from 'js-cookie';
import { Config } from "./config";
const dayN = 1

//设置cookies
const setCookies = (name,val,day)=>{
  return Cookies.set(name,val, { path: '/', domain: Config.cookiesDomainUrl, expires: day?day:dayN })
}

//删除cookies
const removeCookies = (name)=>{
  return Cookies.remove(name, { path: '/', domain: Config.cookiesDomainUrl });
}

//获取cookies
const getCookies = (name)=>{
  return Cookies.get(name);
}

export {
  getCookies,
  setCookies,
  removeCookies
}

